<template>
  <a @click="toggle" class="csn-mm-nav-icon">
    <span class="casino-icon casino-icon-profile"></span>
  </a>
</template>
<script>
import { MY_PROFILE_BUTTON, EventType } from '@/constants'

export default {
  name: MY_PROFILE_BUTTON,
  props: {
    hasNotifications: Boolean,
    listLength: Number,
  },
  methods: {
    toggle() {
      this.$emit(EventType.TOGGLE)
    },
  },
}
</script>
